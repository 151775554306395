<template>
  <el-tree ref="tree" class="highlight-tree" node-key="_id" :data="data" highlight-current :expand-on-click-node="false" default-expand-all :empty-text="loading ? '正在加载…' : '没有数据'" @node-click="handleNodeClick">
    <div class="h c" slot-scope="{data}" :title="data.name" style="overflow: visible !important;">
      <!-- <i :class="treeIcons[data.type]"></i> -->
      <div>&nbsp;{{data.name}}</div>
    </div>
  </el-tree>
</template>

<script>
import initData from "@/mixins/initData";

export default {
  mixins: [initData],
  props: {
    value: Number | String,
    type: String,
  },
  data() {
    return {
      treeIcons: {
        group: "fa fa-file-alt",
        company: "fa fa-file-alt",
        subcompany: "fa fa-file-alt",
        area: "fa fa-file-alt",
        shop: "fa fa-file-alt",
      },
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    beforeInit() {
      this.url = "api/analy/shop/shopTree";
      this.params = { sort: "id,desc" };
      return true;
    },
    buildNodeKey(source) {
      (source || []).forEach((o) => {
        o._id = o.type + "_" + o.id;
        this.buildNodeKey(o.children);
      });
    },
    afterLoad(res) {
      if (res && res.length) {
        this.buildNodeKey(res);
        res.unshift({
          children: null,
          id: "1",
          _id: "1_group",
          isDelete: false,
          name: "所有",
          parentId: null,
          type: "group",
        });
        let change = res[0];
        let autoSelect = res[1];
        setTimeout((_) => {
          this.$refs.tree && this.$refs.tree.setCurrentKey(change._id);
          this.handleNodeClick(autoSelect);
        }, 200);
      }
      return res;
    },
    handleNodeClick(data) {
      if (data.id && data.type) {
        this.$emit("input", data.id);
        this.$emit("update:type", data.type);
        this.$emit("change", data);
      }
    },
  },
};
</script>
