<template>
  <div class="app-container h">
    <div class="v no-flex" style="width: 320px; margin-right: 20px;">
      <div class="bold" style="line-height: 2;">直营门店</div>
      <div class="scroll-able-xy flex" style="border: 1px solid #DCDFE6; border-radius: 4px;">
        <dept-select v-model="query.treeNodeId" :type.sync="query.treeNodeType" @change="toQuery" />
      </div>
    </div>
    <div class="flex v">
      <el-form class="head-container e" label-position="top">
        <el-form-item label="时间范围" class="filter-item">
          <div class="h c">
            <el-date-picker v-model="query.begDate" type="date" value-format="timestamp" placeholder="选择开始日" :clearable="false" style="width:150px" />
            <div class="padding-0-05">至</div>
            <el-date-picker v-model="query.endDate" type="date" value-format="timestamp" placeholder="选择结束日" :clearable="false" style="width:150px" />
          </div>
        </el-form-item>

        <el-form-item label="商品范围" class="filter-item">
          <quick-select url="api/brand" v-model="query.brandIds" filterable clearable placeholder="请选择品牌" style="width: 250px;" multiple />
          <tree-picker v-model="query.seriesId" :params="{brandIds:query.brandIds}" url="api/series/tree" clearable placeholder="请选择系列" style="width: 150px;" v-if="query.brandIds" />
          <tree-picker v-model="query.categoryId" url="api/category/tree" clearable placeholder="请选择分类" style="width: 150px;" />
        </el-form-item>
        <el-form-item label="指定商品" class="filter-item">
          <el-tooltip content="点击选择商品" placement="bottom">
            <div slot="content" v-if="query.goods && query.goods.length" style="max-width: 320px;">
              <template v-for="(g, i) in query.goods">{{g.name}}{{i < query.goods.length - 1 ? "、" : ""}}</template>
            </div>
            <sku-selector request-url="api/goods/sku" button-type :button-text="queryGoodsName" @submit="handleGoodsSelect">
              <i class="el-icon-circle-close padding-0-10" @click.stop="query.goods = null" v-if="query.goods && query.goods.length" />
            </sku-selector>
          </el-tooltip>
        </el-form-item>
        <el-form-item label="排名对象" class="filter-item">
          <el-select v-model="query.shopAreaGroup" placeholder style="width: 150px;">
            <el-option value="area" label="区域" />
            <el-option value="shop" label="门店" />
          </el-select>
        </el-form-item>

        <el-form-item label="只统计已上报" class="filter-item">
          <el-switch v-model="options.reportOnly" @change="handleStatistics" />
        </el-form-item>
        <el-form-item label="排名依据" class="filter-item">
          <el-select v-model="query.saleValueGroup" placeholder style="width: 150px;" v-if="options.reportOnly==false">
            <el-option value="saleNumber" label="销售数量" />
            <el-option value="realSumMoney" label="销售金额" />
            <el-option value="sendNumber" label="发货数量" />
            <el-option value="sendMoney" label="发货金额" />
          </el-select>
          <el-select v-model="query.saleValueGroup" placeholder style="width: 150px;" v-if="options.reportOnly==true">
            <el-option value="checkSendNumber" label="发货数量" />
            <el-option value="realCheckSumMoney" label="销售金额" />
            <el-option value="checkSaleNumber" label="销售数量" />
            <el-option value="checkSendMoney" label="发货金额" />
          </el-select>
        </el-form-item>

        <el-form-item label="开启同比" class="filter-item">
          <el-checkbox size="mini" v-model="query.yearOnYearShow" />
        </el-form-item>
        <el-form-item label="同比年度" class="filter-item" v-if="query.yearOnYearShow">
          <el-date-picker v-model="query.compareYear" type="year" placeholder="选择年" style="width: 150px;" :clearable="false" />
        </el-form-item>
        <el-form-item label="开启环比" class="filter-item">
          <el-checkbox size="mini" v-model="query.compareMonth" />
        </el-form-item>

        <el-form-item class="filter-item">
          <el-button type="success" icon="el-icon-search" :loading="loading" @click="toQuery" :disabled="!searchable">统计</el-button>
          <el-button type="success" @click="toDownload" :disabled="!searchable" icon="el-icon-download" :loading="downloadLoading">导出</el-button>
        </el-form-item>
      </el-form>
      <div class="flex scroll-able">
        <el-collapse :value="['chart', 'data']">
          <el-collapse-item title="分析图表" name="chart">
            <div v-show="store && store.length">
              <el-form class="head-container e" label-position="right" label-width="138px">
                <el-form-item label="图表类型:" class="filter-item">
                  <el-radio-group v-model="chartTypes" size="mini" @change="draw">
                    <el-radio-button label="zzt">柱状图</el-radio-button>
                    <el-radio-button label="tzt">饼图</el-radio-button>
                  </el-radio-group>
                </el-form-item>

                <el-form-item label="显示图表前：" class="filter-item">
                  <el-input-number v-model="chartSize" size="mini" :min="1" :max="1000" :step="1" :precision="0" controls-position="right" @change="loadChartData" />&nbsp;名
                </el-form-item>
              </el-form>
              <div ref="chart"></div>
            </div>
            <div class="fc-g ta-c" v-if="!store || !store.length">没有分析数据，请更改统计分析条件或稍候重试。</div>
          </el-collapse-item>
          <el-collapse-item title="数据表格" name="data">
            <template v-if="store && store.length">
              <el-table row-key="id" border :data="data" size="small" height="530">
                <el-table-column prop="rank" label="名次" width="100" />
                <el-table-column prop="name" label="名称" width="150" show-overflow-tooltip />
                <el-table-column prop="formCount" label="订单总数量" width="150" />
                <el-table-column prop="formMoney" label="均单值" :formatter="this.$price" width="150" />

                <!-- 未上报 -->
                <template v-if="isReported==false">
                  <el-table-column label="销售金额" align="center" key="1">
                    <el-table-column prop="realSumMoney" label="金额" :formatter="this.$price" min-width="150" />
                    <el-table-column prop="compareYearData" label="同比增长" min-width="100">
                      <template slot-scope="scope">
                        <span v-if="scope.row.compareYearData">{{ Math.round(scope.row.compareYearData.realSumMoneyRatio * 10000) / 100}}%</span>
                        <span v-else>--</span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="compMonthData" label="环比增长">
                      <template slot-scope="scope">
                        <span v-if="scope.row.compMonthData">{{ Math.round(scope.row.compMonthData.realSumMoneyRatio * 10000) / 100}}%</span>
                        <span v-else>--</span>
                      </template>
                    </el-table-column>
                  </el-table-column>
                  <el-table-column label="销售数量" align="center" key="2">
                    <el-table-column prop="saleNumber" label="数量" />
                    <el-table-column prop="compareYearData" label="同比增长" min-width="100">
                      <template slot-scope="scope">
                        <span v-if="scope.row.compareYearData">{{ Math.round(scope.row.compareYearData.saleNumberRatio * 10000) / 100}}%</span>
                        <span v-else>--</span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="compMonthData" label="环比增长">
                      <template slot-scope="scope">
                        <span v-if="scope.row.compMonthData">{{ Math.round(scope.row.compMonthData.saleNumberRatio * 10000) / 100}}%</span>
                        <span v-else>--</span>
                      </template>
                    </el-table-column>
                  </el-table-column>
                  <el-table-column label="发货金额" align="center" key="3">
                    <el-table-column prop="sendMoney" label="金额" :formatter="this.$price" min-width="150" />
                    <el-table-column prop="compareYearData" label="同比增长" min-width="100">
                      <template slot-scope="scope">
                        <span v-if="scope.row.compareYearData">{{ Math.round(scope.row.compareYearData.sendMoneyRatio * 10000) / 100}}%</span>
                        <span v-else>--</span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="compMonthData" label="环比增长">
                      <template slot-scope="scope">
                        <span v-if="scope.row.compMonthData">{{ Math.round(scope.row.compMonthData.sendMoneyRatio * 10000) / 100}}%</span>
                        <span v-else>--</span>
                      </template>
                    </el-table-column>
                  </el-table-column>
                  <el-table-column label="发货数量" align="center" key="4">
                    <el-table-column prop="sendNumber" label="数量" />
                    <el-table-column prop="compareYearData" label="同比增长" min-width="100">
                      <template slot-scope="scope">
                        <span v-if="scope.row.compareYearData">{{ Math.round(scope.row.compareYearData.sendNumberRatio * 10000) / 100}}%</span>
                        <span v-else>--</span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="compMonthData" label="环比增长">
                      <template slot-scope="scope">
                        <span v-if="scope.row.compMonthData">{{ Math.round(scope.row.compMonthData.sendNumberRatio * 10000) / 100}}%</span>
                        <span v-else>--</span>
                      </template>
                    </el-table-column>
                  </el-table-column>
                </template>
                <!-- 已上报 -->
                <template v-if="isReported==true">
                  <el-table-column label="销售金额" align="center" key="5">
                    <el-table-column prop="realCheckSumMoney" label="金额" :formatter="this.$price" min-width="100" />
                    <el-table-column prop="compareYearData" label="同比增长" min-width="100">
                      <template slot-scope="scope">
                        <span v-if="scope.row.compareYearData">{{ Math.round(scope.row.compareYearData.realCheckSumMoneyRatio * 10000) / 100}}%</span>
                        <span v-else>--</span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="compMonthData" label="环比增长">
                      <template slot-scope="scope">
                        <span v-if="scope.row.compMonthData">{{ Math.round(scope.row.compMonthData.realCheckSumMoneyRatio * 10000) / 100}}%</span>
                        <span v-else>--</span>
                      </template>
                    </el-table-column>
                  </el-table-column>
                  <el-table-column label="销售数量" align="center" key="6">
                    <el-table-column prop="checkSaleNumber" label="数量" />
                    <el-table-column prop="compareYearData" label="同比增长" min-width="100">
                      <template slot-scope="scope">
                        <span v-if="scope.row.compareYearData">{{ Math.round(scope.row.compareYearData.checkSaleNumberRatio * 10000) / 100}}%</span>
                        <span v-else>--</span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="compMonthData" label="环比增长">
                      <template slot-scope="scope">
                        <span v-if="scope.row.compMonthData">{{ Math.round(scope.row.compMonthData.checkSaleNumberRatio * 10000) / 100}}%</span>
                        <span v-else>--</span>
                      </template>
                    </el-table-column>
                  </el-table-column>
                  <el-table-column label="发货金额" align="center" key="7">
                    <el-table-column prop="checkSendMoney" label="金额" :formatter="this.$price" min-width="100" />
                    <el-table-column prop="compareYearData" label="同比增长" min-width="100">
                      <template slot-scope="scope">
                        <span v-if="scope.row.compareYearData">{{ Math.round(scope.row.compareYearData.checkSendMoneyRatio * 10000) / 100}}%</span>
                        <span v-else>--</span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="compMonthData" label="环比增长">
                      <template slot-scope="scope">
                        <span v-if="scope.row.compMonthData">{{ Math.round(scope.row.compMonthData.checkSendMoneyRatio * 10000) / 100}}%</span>
                        <span v-else>--</span>
                      </template>
                    </el-table-column>
                  </el-table-column>
                  <el-table-column label="发货数量" align="center" key="8">
                    <el-table-column prop="checkSendNumber" label="数量" />
                    <el-table-column prop="compareYearData" label="同比增长" min-width="100">
                      <template slot-scope="scope">
                        <span v-if="scope.row.compareYearData">{{ Math.round(scope.row.compareYearData.checkSendNumberRatio * 10000) / 100}}%</span>
                        <span v-else>--</span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="compMonthData" label="环比增长">
                      <template slot-scope="scope">
                        <span v-if="scope.row.compMonthData">{{ Math.round(scope.row.compMonthData.checkSendNumberRatio * 10000) / 100}}%</span>
                        <span v-else>--</span>
                      </template>
                    </el-table-column>
                  </el-table-column>
                </template>
                <el-table-column key="9" width="70px">
                  <div class="row-commands" slot-scope="scope">
                    <el-button size="mini" type="text" @click="handleDrillStructure(scope.row)">商品结构</el-button>
                  </div>
                </el-table-column>
              </el-table>
              <el-pagination align="center" :current-page.sync="page" :page-sizes="[5,10,20, 50]" :page-size.sync="size" @current-change="loadListData" @size-change="loadListData" layout="total, sizes, prev, pager, next, jumper" :total="store.length" />
            </template>
            <div class="fc-g ta-c" v-else>没有分析数据，请更改统计分析条件或稍候重试。</div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>

    <drill-structure ref="structure" />
  </div>
</template>

<script>
import deptSelect from "./deptSelect";
import skuSelector from "@/views/assembly/skuSelect";
import drillStructure from "./drill/structure";
import request from "@/utils/request";
import { download } from "@/api/data";
import { downloadFile } from "@/utils/index";
export default {
  name: "trend",
  components: {
    deptSelect,
    skuSelector,
    drillStructure,
  },
  data() {
    let now = new Date(this.$now.get());
    return {
      loading: false,
      goodsSpuName: null,
      data: [],
      store: [],
      chartTypes: "zzt",
      page: 1,
      size: 10,
      chartSize: 5,
      chart: null,
      isReported: false,
      options: {
        reportOnly: false,
      },
      query: {
        treeNodeId: null,
        treeNodeType: null,
        begDate: new Date(
          now.getFullYear(),
          now.getMonth() - 1,
          now.getDate()
        ).getTime(),
        endDate: new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate(),
          23,
          59,
          59
        ).getTime(),

        saleValueGroup: "realSumMoney",
        shopAreaGroup: "area",
        yearOnYearShow: false,
        compareYear: new Date(
          now.getFullYear() - 1,
          now.getMonth() - 1,
          now.getDate()
        ).getTime(),
        compareMonth: false,
        goods: null,
        skuId: null,
        brandIds: null,
        seriesId: null,
        categoryId: null,
      },
      downloadLoading: false,
    };
  },

  computed: {
    searchable() {
      return (
        this.query.treeNodeId &&
        this.query.begDate &&
        this.query.endDate &&
        this.query.saleValueGroup
      );
    },
    queryGoodsName() {
      if (this.query.goods && this.query.goods.length) {
        let str = this.query.goods[0].name;
        if (this.query.goods.length > 1)
          str += "等" + this.query.goods.length + "个商品";
        return str;
      }
      return "选择商品";
    },
  },
  methods: {
    handleGoodsSelect(res) {
      if (res) {
        this.query.goods = res;
      }
    },
    makeParams() {
      let data = JSON.parse(JSON.stringify(this.query));
      data.begDate = new Date(data.begDate);
      data.endDate = new Date(data.endDate);
      let sd = new Date(data.compareYear);
      if (this.query.yearOnYearShow == false) {
        delete data.compareYear;
      } else if (this.query.yearOnYearShow == true) {
        data.compareYear = sd.getFullYear();
      }
      data.begDate = new Date(
        data.begDate.getFullYear(),
        data.begDate.getMonth(),
        data.begDate.getDate()
      ).getTime();
      data.endDate = new Date(
        data.endDate.getFullYear(),
        data.endDate.getMonth(),
        data.endDate.getDate() + 1
      ).getTime();
      if (data.goods && data.goods.length)
        data.skuIds = data.goods.map((o) => o.id);
      delete data.goods;
      return data;
    },
    loadChartData() {
      let params = this.makeParams();
      params.page = 0;
      params.size = this.chartSize;

      this.loading = true;
      request({
        url: "@host:analysis;api/analy/shop/sale/rank",
        method: "get",
        params,
      })
        .then((res) => {
          this.store = res.content;
          this.$nextTick(this.draw);
        })
        .catch((err) => {
          this.store = [];
        })
        .finally((_) => {
          this.loading = false;
        });
    },
    loadListData() {
      let params = this.makeParams();
      params.page = this.page - 1;
      params.size = this.size;

      this.loading = true;
      request({
        url: "@host:analysis;api/analy/shop/sale/rank",
        method: "get",
        params,
      })
        .then((res) => {
          this.data = res.content;
        })
        .catch((err) => {
          this.data = [];
        })
        .finally((_) => {
          this.loading = false;
        });
    },
    toQuery() {
      if (this.options.reportOnly == false) {
        this.isReported = false;
      } else {
        this.isReported = true;
      }
      this.page = 1;
      this.loadChartData();
      this.loadListData();
    },
    handleStatistics(val) {
      if (val == true) {
        this.query.saleValueGroup = "realCheckSumMoney";
      } else {
        this.query.saleValueGroup = "realSumMoney";
      }
    },
    draw() {
      if (this.store && this.store.length) {
        let sourceName = []; //x轴
        let sourceData1 = []; //系列1数据
        let sourceData2 = []; //系列2数据
        let sourceData3 = []; //系列3数据
        let binChart = []; //系列3数据
        let binChart2 = [];
        let binChart3 = [];
        let svgp = [];
        let groupRankName = [];
        let legendsvgp = [];
        let yAxisName = "";
        (this.store || []).forEach((o) => {
          sourceName.push(o.name);
          if (
            this.query.saleValueGroup == "saleNumber" ||
            this.query.saleValueGroup == "checkSaleNumber" ||
            this.query.saleValueGroup == "sendNumber" ||
            this.query.saleValueGroup == "checkSendNumber"
          ) {
            sourceData1.push(o[this.query.saleValueGroup]);
            yAxisName = "数量";
          } else {
            sourceData1.push(
              Math.round(o[this.query.saleValueGroup] * 0.000001 * 100) / 100
            );
            yAxisName = "金额（万元）";
          }
        });
        switch (this.query.saleValueGroup) {
          case "realSumMoney":
            legendsvgp.push("销售金额");
            svgp.push("销售金额");
            break;
          case "realCheckSumMoney":
            legendsvgp.push("已上报销售金额");
            svgp.push("已上报销售金额");
            break;
          case "saleNumber":
            legendsvgp.push("单品数量");
            svgp.push("单品数量");
            break;
          case "checkSaleNumber":
            legendsvgp.push("已上报单品数量");
            svgp.push("已上报单品数量");
            break;
          case "sendMoney":
            legendsvgp.push("发货金额");
            svgp.push("发货金额");
            break;
          case "checkSendMoney":
            legendsvgp.push("已上报发货金额");
            svgp.push("已上报发货金额");
            break;
          case "sendNumber":
            legendsvgp.push("发货数量");
            svgp.push("发货数量");
            break;
          case "checkSendNumber":
            legendsvgp.push("已上报发货数量");
            svgp.push("已上报发货数量");
            break;
        }

        switch (this.query.shopAreaGroup) {
          case "area":
            groupRankName.push("区域排名");
            break;
          case "shop":
            groupRankName.push("门店排名");
            break;
        }
        if (this.query.yearOnYearShow == true) {
          legendsvgp.push("同比");
          (this.store || []).forEach((o) => {
            if (o.compareYearData) {
              if (
                this.query.saleValueGroup == "saleNumber" ||
                this.query.saleValueGroup == "checkSaleNumber" ||
                this.query.saleValueGroup == "sendNumber" ||
                this.query.saleValueGroup == "checkSendNumber"
              ) {
                sourceData2.push(o.compareYearData[this.query.saleValueGroup]);
                binChart2.push({
                  name: o.name,
                  value: o.compareYearData[this.query.saleValueGroup],
                });
                yAxisName = "数量";
              } else {
                sourceData2.push(
                  Math.round(
                    o.compareYearData[this.query.saleValueGroup] *
                      0.000001 *
                      100
                  ) / 100
                );
                binChart2.push({
                  name: o.name,
                  value:
                    Math.round(
                      o.compareYearData[this.query.saleValueGroup] *
                        0.000001 *
                        100
                    ) / 100,
                });
                yAxisName = "金额（万元）";
              }
            }
          });
        }
        if (this.query.compareMonth == true) {
          legendsvgp.push("环比");
          (this.store || []).forEach((o) => {
            if (o.compMonthData) {
              if (
                this.query.saleValueGroup == "saleNumber" ||
                this.query.saleValueGroup == "checkSaleNumber" ||
                this.query.saleValueGroup == "sendNumber" ||
                this.query.saleValueGroup == "checkSendNumber"
              ) {
                sourceData3.push(o.compMonthData[this.query.saleValueGroup]);
                binChart3.push({
                  name: o.name,
                  value: o.compMonthData[this.query.saleValueGroup],
                });
                yAxisName = "数量";
              } else {
                sourceData3.push(
                  Math.round(
                    o.compMonthData[this.query.saleValueGroup] * 0.000001 * 100
                  ) / 100
                );
                binChart3.push({
                  name: o.name,
                  value:
                    Math.round(
                      o.compMonthData[this.query.saleValueGroup] *
                        0.000001 *
                        100
                    ) / 100,
                });
                yAxisName = "金额（万元）";
              }
            }
          });
        }

        if (!this.chart) {
          this.chart = echarts.init(this.$refs.chart, "light", {
            height: 420,
          });
        }
        this.chart.clear();
        // 处理饼状图数据
        binChart = (this.store || []).map((o) => {
          if (
            this.query.saleValueGroup == "saleNumber" ||
            this.query.saleValueGroup == "checkSaleNumber" ||
            this.query.saleValueGroup == "sendNumber" ||
            this.query.saleValueGroup == "checkSendNumber"
          ) {
            return {
              name: o.name,
              value: o[this.query.saleValueGroup],
            };
          } else {
            return {
              name: o.name,
              value: o[this.query.saleValueGroup].toFixed(2) / 100,
            };
          }
        });

        if (this.chartTypes == "zzt") {
          this.chart.setOption({
            tooltip: {
              trigger: "axis",
            },
            legend: {
              data: legendsvgp,
            },

            toolbox: {
              show: true,
              right: "4%",
              feature: {
                dataView: { show: true, readOnly: false },
                magicType: { show: true, type: ["line", "bar"] },
                restore: { show: true },
                saveAsImage: { show: true },
              },
            },
            calculable: true,
            grid: {
              y2: 150,
              right: "4%",
              left: "4%",
            },
            xAxis: [
              {
                type: "category",
                data: sourceName,
                axisLabel: {
                  interval: 0, //横轴信息全部显示
                  formatter: function (value) {
                    return value.split("").join("\n");
                  },
                },
              },
            ],
            yAxis: [
              {
                type: "value",
                name: yAxisName,
              },
            ],
            series: [
              {
                name: svgp,
                type: "bar",
                data: sourceData1,
                itemStyle: {
                  normal: {
                    label: {
                      show: true, //开启显示
                      position: "top", //在上方显示
                      textStyle: {
                        //数值样式
                        color: "black",
                        fontSize: 12,
                      },
                    },
                  },
                },
              },
              sourceData2.length > 0
                ? {
                    name: "同比",
                    type: "bar",
                    data: sourceData2,
                    itemStyle: {
                      normal: {
                        label: {
                          show: true, //开启显示
                          position: "top", //在上方显示
                          textStyle: {
                            //数值样式
                            color: "black",
                            fontSize: 12,
                          },
                        },
                      },
                    },
                  }
                : "",
              sourceData3.length > 0
                ? {
                    name: "环比",
                    type: "bar",
                    data: sourceData3,
                    itemStyle: {
                      normal: {
                        label: {
                          show: true, //开启显示
                          position: "top", //在上方显示
                          textStyle: {
                            //数值样式
                            color: "black",
                            fontSize: 12,
                          },
                        },
                      },
                    },
                  }
                : "",
            ],
          });
        } else if (this.chartTypes == "tzt") {
          if (!this.chart) {
            this.chart = echarts.init(this.$refs.chart, "light", {
              height: 420,
            });
          }
          this.chart.clear();
          this.chart.setOption({
            title: {
              left: "center",
              top: 20,
            },
            tooltip: {
              trigger: "item",
              formatter: "{a} <br/>{b} : {c} ({d}%)",
            },
            series: [
              {
                name: svgp + groupRankName,
                type: "pie",
                radius: "55%",
                center: ["15%", "50%"],
                data: binChart.sort(function (a, b) {
                  return a.value - b.value;
                }),
                roseType: "radius",
                itemStyle: {
                  normal: {
                    label: {
                      show: false,
                    },
                    labelLine: {
                      show: false,
                    },
                  },
                },
              },
              {
                name: svgp + groupRankName + "同比分析",
                type: "pie",
                radius: "55%",
                center: ["45%", "50%"],
                data: binChart2.sort(function (a, b) {
                  return a.value - b.value;
                }),
                roseType: "radius",
                itemStyle: {
                  normal: {
                    label: {
                      show: false,
                    },
                    labelLine: {
                      show: false,
                    },
                  },
                },
              },
              {
                name: svgp + groupRankName + "环比分析",
                type: "pie",
                radius: "55%",
                center: ["80%", "50%"],
                data: binChart3.sort(function (a, b) {
                  return a.value - b.value;
                }),
                roseType: "radius",
                itemStyle: {
                  normal: {
                    label: {
                      show: false,
                    },
                    labelLine: {
                      show: false,
                    },
                  },
                },
              },
            ],
          });
        }
      } else {
        this.chart = null;
      }
    },

    handleDrillStructure(row) {
      let params = this.makeParams();
      params.treeNodeType = params.shopAreaGroup;
      params.treeNodeId = row.id;
      this.$refs.structure && this.$refs.structure.resetQuery(params, row);
    },

    toDownload() {
      let params = this.makeParams();
      params.onlyReport = this.options.reportOnly;
      this.downloadLoading = true;
      download("@host:analysis;api/analy/shop/download/analyShopRank", params)
        .then((result) => {
          downloadFile(result, "门店销售排行", "xlsx");
          this.downloadLoading = false;
        })
        .catch((err) => {
          this.$message.error(err.message || "操作失败，请稍候再试。");
          this.downloadLoading = false;
        });
    },
  },
};
</script>

