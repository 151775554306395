<template>
  <div>
    <el-button :type="buttonType" :size="buttonSize" :icon="buttonIcon" :loading="buttonLoading" :disabled="buttonDisabled" @click="open" v-if="showButton">
      <span>{{buttonText}}</span>
      <slot></slot>
    </el-button>

    <el-dialog :visible.sync="dialog" :title="title" append-to-body :close-on-click-modal="false" @closed="handleClosed" width="1200px">
      <div class="fc-g" style="margin-bottom: 12px;" v-if="tip">{{tip}}</div>
      <slot name="tip"></slot>
      <div class="head-container">
        <template v-if="!simpleQuery">
          <quick-select url="api/brand" v-model="query.brandId" filterable placeholder="品牌" class="filter-item" clearable style="width: 200px;" />
          <quick-cascader class="filter-item" v-model="query.seriesId" url="api/series/tree" :params="{enabled: true}" root-key check-strictly filterable clearable placeholder="系列" expand-trigger="hover" style="width: 200px;" />
          <!-- <quick-cascader class="filter-item" v-model="query.categoryId" url="api/category/tree" filterable clearable placeholder="分类" expand-trigger="hover" style="width: 10px;" /> -->
        </template>
        <el-input v-model="query.keywords" clearable placeholder="商品编码/ERP编码/商品名称" :validate-event="false" style="width: 240px;" class="filter-item" @keyup.enter.native="toQuery" />
        <el-input v-model="query.specs" clearable placeholder="商品规格" :validate-event="false" style="width: 280px;" class="filter-item" @keyup.enter.native="toQuery" />
        <el-button type="success" icon="el-icon-search" class="filter-item" @click.stop="toQuery">搜索</el-button>
      </div>

      <el-table ref="list" row-key="id" :data="data" :highlight-current-row="!multiple" v-loading="loading" @current-change="handleSelectChange" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="35" reserve-selection :selectable="checkSelectable" v-if="multiple" />
        <el-table-column prop="code" label="商品编码" width="115" />
        <el-table-column prop="erpCode" label="ERP编码" width="115" />
        <el-table-column prop="name" label="商品名称" show-overflow-tooltip min-width="110" />
        <el-table-column prop="specs" label="规格" min-width="280" :formatter="$goodsSpecConvert" />
        <!-- <el-table-column prop="brandName" label="品牌" width="65" show-overflow-tooltip/> -->
        <template v-if="showDetails">
          <template v-if="shelf">
            <el-table-column key="shelf" prop="onShelf" label="是否上架" width="70" align="center">
              <i slot-scope="scope" :class="scope.row.onShelf && scope.row.enable ? 'el-icon-check' : ''"></i>
            </el-table-column>
          </template>
          <template v-if="retail">
            <el-table-column key="stock" prop="countInfo.warehouseCount" label="库存" width="80" align="right" />
            <el-table-column key="saleStock" label="可售数量" width="80" align="right" :formatter="r => { return Math.round(((r.countInfo.warehouseCount - r.countInfo.saleCount)<0?0:(r.countInfo.warehouseCount - r.countInfo.saleCount)) * 100) / 100; }" />
          </template>
          <!-- <template v-else> -->
            <!-- <el-table-column key="price" label="单价" width="100" align="right">
              <template slot-scope="scope">{{$price(scope.row.price)}}起</template>
            </el-table-column>-->
          <!-- </template> -->
        </template>

        <template v-if="checkPermission(['PURCHASE_ALL','PURCHASE_TOTALMONEY'])">
          <el-table-column label="价格" width="150" v-if="showPrice" align="center">
            <template slot-scope="scope">
              <b class="fc-e" v-if="scope.row._price">{{$price(scope.row._price)}}</b>
              <el-button v-else size="mini" type="text" @click="handleGetPrice(scope.row)" :loading="scope.row._priceLoading">获取价格</el-button>
            </template>
          </el-table-column>
        </template>
      </el-table>
      <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />
      <div style="margin-top: 12px;" v-if="multiple">
        <el-button type="primary" :disabled="loading || !selection || !selection.length" @click="handleSubmit">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import initData from "@/mixins/initData";
import checkPermission from "@/utils/permission";
import request from "@/utils/request";
export default {
  mixins: [initData],
  props: {
    showPrice: {
      type: Boolean,
      default: false,
    },
    showButton: {
      type: Boolean,
      default: true,
    },
    showDetails: {
      type: Boolean,
      default: true,
    },
    buttonType: {
      type: String,
      default: "primary",
    },
    buttonIcon: String,
    buttonSize: {
      type: String,
      default: "small",
    },
    buttonText: {
      type: String,
      default: "选择商品",
    },
    buttonLoading: Boolean,
    buttonDisabled: Boolean,
    multiple: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "选择商品",
    },
    purchaseLimit: Boolean,
    keepQuery: Boolean,
    simpleQuery: Boolean,
    tip: String,
    requestUrl: String,
    requestParams: Object,
    excludeKeys: Array,
    retail: Boolean,
    shelf: Boolean,
  },
  data() {
    return {
      dialog: false,
      inited: false,
      selection: null,
      _price: null,
      priceLoading: null,
      query: {
        brandId: null,
        categoryId: null,
        seriesId: null,
        keywords: null,
        specs: null,
      },
    };
  },
  methods: {
    checkPermission,
    checkSelectable(row) {
      let enable = true;
      if (this.purchaseLimit && row.purchaseLimit) {
        enable = false;
      } else if (this.excludeKeys && this.excludeKeys.length) {
        enable =
          this.excludeKeys.findIndex((ek) => {
            if (this.$isPlainObject(ek)) {
              return row.id === ek.id;
            } else {
              return row.id === ek;
            }
          }) < 0;
      }
      return enable;
    },
    checkGoods(goods) {
      return new Promise((resolve, reject) => {
        if (goods && goods.length && this.shelf) {
          let uss = goods
            .filter((g) => !g.onShelf)
            .map((g) => {
              return `${g.name}${
                g.specs ? `(${this.$goodsSpecConvert(g.specs)})` : ""
              }`;
            });
          if (uss && uss.length) {
            this.$alert(
              `以下商品已经下架，无法再进行采购，请确认库存是否充足。<br /><b class='fc-e'>${uss.join(
                "<br />"
              )}</b>`,
              "系统提示",
              {
                type: "warning",
                dangerouslyUseHTMLString: true,
                showClose: false,
              }
            ).then(resolve);
          } else {
            resolve();
          }
        } else {
          resolve();
        }
      });
    },
    beforeInit() {
      this.url = this.requestUrl;
      this.params = Object.assign(
        {},
        this.query || {},
        this.requestParams || {}
      );
      this.params.enable = true;
      this.inited = true;
      return true;
    },
    afterLoad(data) {
      (data || []).forEach((g) => {
        g._price = null;
        g._priceLoading = false;
      });
    },
    handleSelectionChange(s) {
      if (this.multiple) {
        this.selection = s;
      }
    },
    handleSelectChange(r) {
      if (!this.multiple && r) {
        this.checkGoods([r]).then(() => {
          this.$emit("submit", r);
          this.dialog = false;
        });
      }
    },
    open() {
      this.dialog = true;
      if (!this.keepQuery) {
        for (let k in this.query) {
          if (this.query.hasOwnProperty(k)) {
            this.query[k] = null;
          }
        }
      }
      this.toQuery();
    },
    handleClosed() {
      this.selection = null;
      this.$refs.list && this.$refs.list.setCurrentRow();
      this.$refs.list && this.$refs.list.clearSelection();
    },
    handleSubmit() {
      if (this.multiple) {
        this.checkGoods(this.selection).then(() => {
          this.$emit("submit", this.selection);
          this.dialog = false;
        });
      }
    },
    handleGetPrice(g) {
      if (g) {
        g._priceLoading = true;
        request({
          url: "api/purchaseGoods/price",
          method: "get",
          params: {
            sellerId: g.entId,
            goodsId: g.id,
          },
        })
          .then((res) => {
            let v = res.goodsPrice;
            g._price = v < 0 ? 0 : v;
          })
          .finally((_) => {
            g._priceLoading = false;
          });
      }
    },
  },
};
</script>